
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/affiliate-program-overview",
      function () {
        return require("private-next-pages/affiliate-program-overview/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/affiliate-program-overview"])
      });
    }
  